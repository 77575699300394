import axios from "axios";

export class PracticeGroupDTO {
  constructor(public practiceGroupId: number, public name: string) {}

  static buildFromJson = (json: Record<string, any>): PracticeGroupDTO => {
    return new PracticeGroupDTO(json.practice_group_id, json.name);
  };

  toFormJson(): any {
    return { value: this.practiceGroupId, text: this.name };
  }
}

export class PracticeGroupsApi {
  private static translateApiResponse(response: any) {
    return response.data.resp.map((entry: any) => {
      return PracticeGroupDTO.buildFromJson(entry);
    });
  }

  static async getAll() {
    const response = await axios.get("/api/vets/prototype/practice-groups");
    return this.translateApiResponse(response);
  }

  static async filterByName(name: string) {
    const response = await axios.get("/api/vets/prototype/practice-groups", {
      params: {
        name_filter: name
      }
    });
    return this.translateApiResponse(response);
  }

  static async filterById(practiceGroupId: number) {
    const response = await axios.get(`/api/vets/prototype/practice-groups/${practiceGroupId}`);
    return PracticeGroupDTO.buildFromJson(response.data.resp);
  }
}
