
import { PracticeGroupDTO, PracticeGroupsApi } from "@/api/v2/practice-groups";
import Vue from "vue";
import { SignUpAPI } from "@/api/v2/sign-up";
import { validators } from "@/validators";
import Cookies from "js-cookie";
import PreregistrationCustomerService from "@/services/customer/preregistrationCustomer.service";
import { RouteNamesV2 } from "@/router/routeNames";

export default Vue.extend<any, any, any, any>({
  name: "CreateCustomer",
  data() {
    return {
      signUpData: {
        practiceGroupId: null,
        email: "",
        first: "",
        last: "",
        dataCaptureConsent: false,
        termsAndConditionsConsent: false,
        marketingConsent: false
      },
      submitEnabled: false,
      errorMessage: "",
      errorMessages: {
        wrongMagicLink: "Your link has expired. Please generate another by completing this form."
      },
      rules: {
        emailRules: [validators.required("E-mail"), validators.email()],
        practiceGroupRules: [validators.required("Practice group")],
        requiredRule: validators.required(),
        lengthRule: (v: string) => v.length >= 2 || "This field must contain at least 2 characters."
      },
      searchDelayTimer: null,
      dataCaptureModal: false,
      apiError: null,
      practiceGroups: [],
      practiceGroupsFilter: null,
      practiceGroupSelectorDisabled: false,
      givenPracticeGroupId: this.$route.params.practiceGroupId
        ? +this.$route.params.practiceGroupId
        : null
    };
  },
  methods: {
    async loadFormRemoteData() {
      if (this.givenPracticeGroupId) {
        try {
          const response = await PracticeGroupsApi.filterById(this.givenPracticeGroupId);
          this.practiceGroups = [response.toFormJson()];
          this.signUpData.practiceGroupId = this.givenPracticeGroupId;
          this.practiceGroupSelectorDisabled = true;
        } catch (e) {
          // No practice group selected
          this.givenPracticeGroupId = null;
          this.practiceGroupSelectorDisabled = false;
        }
      }
    },
    async submitFrom() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let token = "";
      try {
        token = await SignUpAPI.createCustomerSignupData(this.signUpData);
      } catch (error) {
        if (error.statusCode === 409) {
          await this.$router.push({
            name: RouteNamesV2.EMAIL_EXISTS
          });
        }
        this.apiError = error.errors;
        return;
      }
      Cookies.set("preRegistrationToken", token);
      await this.$router.push({
        name: RouteNamesV2.CREATE_PET
      });
    },
    openInNewTab(url: string): void {
      return window.open(url, "_blank")?.focus();
    }
  },
  watch: {
    practiceGroupsFilter(newVal: string) {
      if (this.givenPracticeGroupId) {
        return;
      }
      if (!newVal) {
        this.practiceGroups = [];
        return;
      }
      clearTimeout(this.searchDelayTimer);
      this.searchDelayTimer = setTimeout(async () => {
        const practiceGroupsResponse = await PracticeGroupsApi.filterByName(newVal);
        this.practiceGroups = practiceGroupsResponse.map((entry: PracticeGroupDTO) => {
          return entry.toFormJson();
        });
      }, 1);
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      const jwt = to.query.token;
      Cookies.set("preRegistrationToken", jwt);

      const pets = await PreregistrationCustomerService.getCustomersPartialPets();
      if (pets.length === 0) {
        next({ name: RouteNamesV2.CREATE_PET });
        return;
      }
      if (pets.length === 1 && pets[0].name === "") {
        // to cover existing empty pets that were created in single dog flow
        next({ name: RouteNamesV2.CREATE_PET, params: { petId: pets[0].petId } });
        return;
      }
      next({ name: RouteNamesV2.PET_SELECTION });
      return;
    }
    next();
  },
  async mounted() {
    if (this.$route.query.error) {
      this.errorMessage = this.errorMessages[this.$route.query.error];
    }
    await this.loadFormRemoteData();
  }
});
